import React, { ChangeEvent, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Container,
  Grid,
  TextField,
  Theme,
  Typography,
} from '@mui/material';
import { createStyles, makeStyles, styled } from '@mui/styles';
import { PaymentSelection } from '../../shared/constans';
import { ButtonDefault } from '../../shared/components/ButtonDefault';
import InputMask from 'react-input-mask';
import {
  createChargeSubscription,
  createOpenPayInstance,
  createSubscriptionOpenPay,
} from '../openpay/openpay-utils';
import { useAuth } from '../auth/auth-hook';
import { PaymentTime, PaymentType } from '../openpay/openpay-constants';
import cardValidator from 'card-validator';
import { DropDown2 } from './components/DropDown2';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { PaypalButton } from '../paypal/PaypalButton';
import { openDialogAlert } from '../alert/alert-actions';
import { CreateOpenPayChargeResponse } from '../../shared/types/generated';
import { PixelFacebookEvent } from '../../shared/utils/PixelFacebookEvent';

const BoxCheckout = styled(Box)({
  maxWidth: '913px',
  borderRadius: 5,
  boxShadow: '0px 3px 25px #00000012',
  justifyContent: 'center',
  margin: '15px auto',
  border: '1px solid #999999',
  padding: '20px 30px',
});

const PaymentMethodCard = styled(Box)({
  width: 150,
  borderRadius: 5,
  height: 90,
  display: 'flex',
  boxShadow: '0px 3px 25px #00000012',
  justifyContent: 'center',
  cursor: 'pointer',
  alignItems: 'center',
  margin: '0 5px',
});

const StyledButton = styled(ButtonDefault)({
  borderRadius: '5px!important',
  backgroundColor: '#FE0000!important',
  border: 'none!important',
  color: 'white!important',
  '&:disabled': {
    backgroundColor: '#FFCCCC!important',
  },
  height: 45,
});
const useStyles = makeStyles<Theme>(() =>
  createStyles({
    cleanButton: {
      background: 'none!important',
      border: 'none!important',
      cursor: 'pointer',
      color: '#949494!important',

      margin: '0 0',
    },
    textStart: {
      textAlign: 'start',
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
    },
  }),
);

type CreditCardType = {
  cardHolderName: string;
  expiredDate: string;
  cvc: string;
  creditCardNumber: string;
};

export const PaymentMethods: React.FC = () => {
  const navigate = useNavigate();
  const { subscription } = useAuth();
  const [selected, setSelected] = useState<PaymentSelection>(
    PaymentSelection.CreditCard,
  );
  const [searchParams] = useSearchParams();
  const { user } = useAuth();
  const [loading, setLoading] = useState<boolean>(false);
  const [creditCard, setCreditCard] = useState<CreditCardType>({
    cardHolderName: '',
    expiredDate: '',
    cvc: '',
    creditCardNumber: '',
  });
  const [selectedSubscription, setSelectedSubscription] = useState(
    (searchParams.get('payment_type') as PaymentTime)
      ? (searchParams.get('payment_type') as PaymentTime)
      : PaymentTime.Daily,
  );

  useEffect(() => {
    createOpenPayInstance();
  }, []);
  useEffect(() => {
    if (subscription) {
      navigate('/');
    }
  });
  const onChangeHandler = (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ): void => {
    setCreditCard({
      ...creditCard,
      [event.target.name as keyof CreditCardType]: event.target.value as string,
    });
  };

  const onHandlePayment = async (): Promise<void> => {
    setLoading(true);

    const checkCreditCardField = Object.keys(creditCard).find(
      (_creditCard) => creditCard[_creditCard as keyof CreditCardType] === '',
    );

    if (checkCreditCardField) {
      setLoading(false);
      return openDialogAlert('Todos los campos de la tarjeta son requeridos');
    }

    if (!cardValidator.number(creditCard.creditCardNumber).isValid) {
      setLoading(false);
      return openDialogAlert('El numero de tarjeta es incorrecto');
    }

    if (!cardValidator.cvv(creditCard.cvc).isValid) {
      setLoading(false);
      return openDialogAlert('El CVV es incorrecto');
    }

    if (!cardValidator.expirationDate(creditCard.expiredDate).isValid) {
      setLoading(false);

      return openDialogAlert('La fecha de expiracion es incorrecta');
    }

    const date = cardValidator.expirationDate(creditCard.expiredDate);

    const sanitizeCreditCard = {
      card_number: (creditCard.creditCardNumber as string).replaceAll(' ', ''),
      holder_name: creditCard.cardHolderName as string,
      cvv2: creditCard.cvc as string,
      expiration_month: date.month as string,
      expiration_year: date.year as string,
    };
    const chargeData = {
      email: user?.email as string,
      card: sanitizeCreditCard,
      subscription: {
        paymentMethod: PaymentType.Plan1,
        frequency: selectedSubscription,
      },
    };
    if (
      selectedSubscription === PaymentTime.Daily ||
      selectedSubscription === PaymentTime.Annually
    ) {
      await createChargeSubscription(
        chargeData,
        (data: CreateOpenPayChargeResponse) => {
          setLoading(false);
          if (data.success) {
            window.location.href = data.redirectUrl as string;
          } else {
            return openDialogAlert(data.message as string);
          }
        },
      );
    } else {
      await createSubscriptionOpenPay(
        sanitizeCreditCard,
        user?.email as string,
        PaymentType.Plan1,
        selectedSubscription,
        (response) => {
          setLoading(false);
          if (response.success) {
            window.location.href =
              'https://www.noroeste.com.mx/premium-completado';
          } else {
            return openDialogAlert(response.message as string);
          }
        },
      );
    }
  };
  const classes = useStyles();

  interface IDictionary {
    [index: string]: { name: string; price: string; legend: string };
  }

  const subscriptionOptions: IDictionary = {
    daily: { name: 'Mensual', price: '10', legend: 'mes' },
    monthly: { name: 'Semestral', price: '499', legend: '6 meses' },
    annually: { name: 'Anual', price: '599', legend: 'año' },
  };
  const subscriptionList = [
    { label: 'Mensual', value: PaymentTime.Daily },
    { label: 'Semestral', value: PaymentTime.Monthly },
    { label: 'Anual', value: PaymentTime.Annually },
  ];

  return (
    <>
      <PixelFacebookEvent id="payment" />
      <Container>
        <Box display={'flex'} maxWidth={'980px'} mx={'auto'}>
          <Button
            onClick={() => navigate('/suscripciones')}
            className={classes.cleanButton}
          >
            &lt;&lt; Atras
          </Button>
        </Box>
        <BoxCheckout mb={6}>
          <Box>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              borderBottom={'1px solid #999999'}
              color={'#999999'}
              mb={2}
            >
              <Typography fontSize={18}>Informacion detallada</Typography>
              <Typography fontSize={18}>Total</Typography>
            </Box>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Box position={'relative'}>
                <Box>
                  <div className={classes.textStart}>
                    <DropDown2
                      selected={selectedSubscription}
                      items={subscriptionList}
                      onChange={({ value }) => {
                        setSelectedSubscription(value as PaymentTime);
                      }}
                    ></DropDown2>
                  </div>
                </Box>
              </Box>
              <Typography fontWeight={'700'}>
                MXN ${subscriptionOptions[selectedSubscription as string].price}
              </Typography>
            </Box>
          </Box>
        </BoxCheckout>
        <BoxCheckout>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item md={4}>
              <Grid container>
                <Grid item xs={12}>
                  <Typography
                    style={{ fontSize: 16, fontWeight: 'bold' }}
                    align={'center'}
                    mb={2}
                  >
                    Elige un método de pago:
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      marginBottom: 20,
                    }}
                  >
                    <PaymentMethodCard
                      onClick={() => setSelected(PaymentSelection.CreditCard)}
                      style={{
                        border:
                          selected === PaymentSelection.CreditCard
                            ? '1px solid #FE0000'
                            : 'none',
                      }}
                    >
                      <img
                        src={'/card.svg'}
                        alt="paypal"
                        width={28}
                        height={33}
                      />
                      <div
                        style={{ fontSize: 15, lineHeight: 15, marginLeft: 10 }}
                      >
                        Tarjeta
                      </div>
                    </PaymentMethodCard>
                    <PaymentMethodCard
                      onClick={() => setSelected(PaymentSelection.PayPal)}
                      style={{
                        border:
                          selected === PaymentSelection.PayPal
                            ? '1px solid #FE0000'
                            : 'none',
                      }}
                    >
                      <img
                        src={'/paypal.svg'}
                        alt="paypal"
                        width={28}
                        height={33}
                      />
                      <div
                        style={{ fontSize: 15, lineHeight: 13, marginLeft: 10 }}
                      >
                        PayPal
                      </div>
                    </PaymentMethodCard>
                  </div>
                </Grid>
                {selected === PaymentSelection.CreditCard ? (
                  <Grid item xs={12}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <TextField
                          label={'Nombre del titular'}
                          name="cardHolderName"
                          fullWidth
                          variant="standard"
                          InputLabelProps={{
                            shrink: true,
                            style: { fontSize: 19 },
                          }}
                          onChange={(event) => onChangeHandler(event)}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <InputMask
                          mask="9999 9999 9999 9999"
                          maskChar=" "
                          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                          //@ts-ignore
                          maskPlaceholder={null}
                          onChange={(event) => onChangeHandler(event)}
                        >
                          <TextField
                            label={'Número de tarjeta'}
                            name="creditCardNumber"
                            fullWidth
                            variant="standard"
                            InputLabelProps={{
                              shrink: true,
                              style: { fontSize: 19 },
                            }}
                          />
                        </InputMask>
                      </Grid>

                      <Grid item xs={6}>
                        <InputMask
                          mask="99/99"
                          maskChar=" "
                          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                          //@ts-ignore
                          maskPlaceholder={null}
                          onChange={(event) => onChangeHandler(event)}
                        >
                          <TextField
                            label={'Fecha de vencimiento'}
                            name="expiredDate"
                            fullWidth
                            variant="standard"
                            InputLabelProps={{
                              shrink: true,
                              style: { fontSize: 19 },
                            }}
                          />
                        </InputMask>
                      </Grid>
                      <Grid item xs={6}>
                        <InputMask
                          mask="999"
                          maskChar=" "
                          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                          //@ts-ignore
                          maskPlaceholder={null}
                          onChange={(event) => onChangeHandler(event)}
                        >
                          <TextField
                            label={'CVV/CVC'}
                            name="cvc"
                            fullWidth
                            variant="standard"
                            InputLabelProps={{
                              shrink: true,
                              style: { fontSize: 19 },
                            }}
                          />
                        </InputMask>
                      </Grid>
                      <Grid item xs={12}>
                        <StyledButton
                          variant="contained"
                          size="large"
                          color="secondary"
                          fullWidth
                          isLoading={loading}
                          onClick={() => onHandlePayment()}
                        >
                          Pagar
                        </StyledButton>
                        <Typography
                          style={{ fontSize: 15 }}
                          align={'center'}
                          mb={2}
                          paddingTop={'15px'}
                        >
                          {selectedSubscription === PaymentTime.Daily ? (
                            <>
                              *Al hacer el pago acepta que se hará un cargo por
                              $
                              {parseInt(
                                subscriptionOptions[
                                  selectedSubscription as string
                                ].price,
                              ) * 3}{' '}
                              por tres meses y a partir del cuarto mes $99 por{' '}
                              {
                                subscriptionOptions[
                                  selectedSubscription as string
                                ].legend
                              }
                              .
                            </>
                          ) : selectedSubscription === PaymentTime.Monthly ? (
                            <>
                              *Al hacer el pago acepta que se hará un cargo de $
                              {
                                subscriptionOptions[
                                  selectedSubscription as string
                                ].price
                              }{' '}
                              cada{' '}
                              {
                                subscriptionOptions[
                                  selectedSubscription as string
                                ].legend
                              }
                              .
                            </>
                          ) : (
                            <>
                              *Al hacer el pago acepta que se hará un cargo por
                              $
                              {
                                subscriptionOptions[
                                  selectedSubscription as string
                                ].price
                              }{' '}
                              el primer año y $899 por{' '}
                              {
                                subscriptionOptions[
                                  selectedSubscription as string
                                ].legend
                              }{' '}
                              a partir del segundo.
                            </>
                          )}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                ) : (
                  <Grid item xs={12}>
                    <PaypalButton frequency={selectedSubscription} />
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </BoxCheckout>
      </Container>
    </>
  );
};
